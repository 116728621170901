/**
 * Module for Blog Page
 */
import axios from 'axios'
/**
 * ******************************************************************
 * MODULE VARIABLES
 * ******************************************************************
 */

let API,
    currentPage = 2 // the first page is already loaded, so the next one is 2.

/**
 * ******************************************************************
 * CONFIGURATION
 * ******************************************************************
 */

const config = {
    container: $('.blog-listing'),
    loadMoreButton: $('#blog-load-more-button'),
    loadingIcon: $('#blog-load-more')
}
const apiConfig = {
    timeout: 20000,
    headers: {
        'Content-Type': 'application/json'
    }
}

/**
 * ******************************************************************
 * MAIN CONTROLLER
 * ******************************************************************
 */

function blogLoadingController() {
    // set up api
    API = axios.create(apiConfig)
    // setup event listeners
    bindEventListeners()
}

/**
 * ******************************************************************
 * LISTENERS
 * ******************************************************************
 */

function bindEventListeners() {
    config.loadMoreButton.on('click', function(e) {
        e.preventDefault()
        actionLoadMore()
    })
}

/**
 * ******************************************************************
 * ACTION FUNCTIONS
 * ******************************************************************
 */
function actionLoadMore() {
    setLoadingState('loading')
    // check what the url is
    const isCategoryPage = checkIfCategoryPage()
    const pageCategory = isCategoryPage && getPageCategory() ? getPageCategory() : ''
    // send an async request
    API.request({
        method: 'GET',
        url: '/api/blog.json',
        params: {
            category: pageCategory,
            page: getCurrentPage()
        }
    })
        .then(handleCompletion)
        .catch(onFail)
}

function handleCompletion(response) {
    const posts = response.data.data
    const { current_page, total_pages } = response.data.meta.pagination
    // Check if this is the last page of elements
    if ( current_page === total_pages ) {
        onLastPage(posts)
    } else {
        onComplete(posts)
    }
}

function onComplete(posts) {
    const postsTemplates = posts.map(blogPostTemplate)
    config.container.append(postsTemplates)
    incrementPage()
    setLoadingState('complete')
}

function onLastPage(posts) {
    onComplete(posts)
    config.loadMoreButton.hide()
}

function onFail(error) {
    throw error
}

function setLoadingState(state) {
    if (state === 'loading') {
        config.loadMoreButton.hide()
        config.loadingIcon.show()
        config.loadingIcon.addClass('loading')
    } else {
        config.loadMoreButton.show()
        config.loadingIcon.hide()
    }
}

/**
 * ******************************************************************
 * HELPER FUNCTIONS
 * ******************************************************************
 */

function checkIfCategoryPage() {
    const isCategoryPage = window.hasCategory
    return isCategoryPage
}

function getPageCategory() {
    return window.blogCategory ? window.blogCategory : null
}

function getCurrentPage() {
    return currentPage
}

function incrementPage() {
    currentPage = currentPage + 1
}

/**
 * ******************************************************************
 * TEMPLATE FUNCTIONS
 * ******************************************************************
 */
function blogPostTemplate(post) {
    const createTags = categories => categories.map(cat => {
        return `<span class="cat">${ cat }</span>`
    }).join('')

    const postTemplate = `
        <a href="${ post.url }" class="blog-listing-row">
        <div class="article-content">
            ${ post.image ? `<img class="article-list-thumb" width="275" height="275" alt=""
                src="${ post.image }" />` : '' }
            ${ post.categories ? `
                <small>
                    <i class="icon-tag"></i>
                    ${ createTags(post.categories) }
                </small>
            ` : '' }
            <h3>${ post.title }</h3>
            <p>${ post.contentExcerpt }</p>
            <div class="postBy">
                ${ post.authorPhoto ? `<img alt="" src="${ post.authorPhoto }"
                    srcset="${ post.authorPhoto } 2x"
                    class="avatar avatar-40 photo"
                    height="40"
                    width="40"/>` : '' }
                <div class="pName">
                    <span>by ${ post.authorName }</span>
                </div>
            </div>
        </div>
    </a>
    `
    return postTemplate
}





export default function init() {
    if (!window.location.pathname.includes('blog')) return

    blogLoadingController()
}
