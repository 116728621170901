/**
 * SAMPLES FUNCTIONALITY
 * This portion handles the add to cart functionality for samples.
 */
import productAPI from '../productFilters/api'
import { updateCartQuantity, getCartQuantity } from './cartButtonEvents'
import {changeButtonText, setButtonEnabled, setButtonState} from './addSampleButtonEvents'

const samplesConfig = {
    orderSamplesButton: document.querySelector('#order-samples-button'),
    errorText: document.querySelector('.error-text'),
    loadingButtonText: 'ADDING SAMPLE TO CART...',
    standardButtonText: document.querySelector('#order-samples-button') ? document.querySelector('#order-samples-button').textContent.trim() : 'ORDER FREE SAMPLE',
    fullCart: '3/3 SAMPLES SELECTED',
    itemInCart: 'ADDED TO CART'
}

/**
 * This is a color option that has been turned into a purchasable.
 * @param {Node} purchasable The color option to be turned into a purchasable.
 */
class PurchasableOption {
    constructor(purchasable) {
        this.purchasable = purchasable
        this.purchasableId = purchasable.getAttribute('data-purchasable') || null
        this.isAvailable = purchasable.getAttribute('data-available') === '1'
        this.productHash = purchasable.getAttribute('data-product-hash')
        this._bindEvents()
    }

    _bindEvents() {
        this.purchasable.addEventListener('click', () => {
            this.addPurchasableToWindow()
            this._handleButtonTextChange()
        })
    }

    /**
     * METHODS
     */

    addPurchasableToWindow() {
        window.currentPurchasable = this.purchasableId
        this._handleButtonTextChange()
    }

    _handleButtonTextChange() {
        const isInCart = PurchasableOption.checkIfInCart(this.purchasableId)
        if (isInCart) {
            changeButtonText(samplesConfig.itemInCart)
            setButtonEnabled(false)
        } else {
            setButtonEnabled(this.isAvailable)
            changeButtonText(samplesConfig.standardButtonText)
        }
    }

    static checkIfInCart(purchasableId) {
        const itemsInCartIds = window.cartItemIds || []
        let itemIsInCart = false
        itemsInCartIds.forEach(item => {
            if (item === purchasableId) {
                itemIsInCart = true
            }
        })
        return itemIsInCart
    }

    /**
     * GETTERS
     */

    getPurchasableId() {
        return this.purchasableId
    }

    getProductHash() {
        return this.productHash;
    }

    getPurchasable() {
        return this.purchasable
    }
}


/**
 * This creates the custom events.
 */
function createEvents() {
    const orderSamplesEvent = new Event('order-samples')
    return {
        orderSamplesEvent
    }
}


/**
 * This function is what handles adding the current purchasable to the cart
 * and updates it, as well as updates the buttons.
 */
function actionOrderSamples() {
    // get the id
    const purchasableId = window.currentPurchasable
    const currentCartQuantity = getCartQuantity()
    const itemAlreadyInCart = PurchasableOption.checkIfInCart(purchasableId)

    if (currentCartQuantity < 3 && !itemAlreadyInCart) {
        // Change loading text
        changeButtonText(samplesConfig.loadingButtonText)

        // make the post api call
        let params = new URLSearchParams({
            action: 'commerce/cart/update-cart',
            [window.pageInfo.csrfTokenName]: window.pageInfo.csrfToken
        })

        // Actually add the sample item to the params
        params.append('purchasables[][id]', purchasableId)

        productAPI.postSamples(params.toString())
            .then(response => {
                const newCart = response.data.cart
                if (newCart) {
                    updateCartQuantity(newCart.totalQty)
                    updateWindowLineItems(newCart.lineItems)
                }
                changeButtonText(samplesConfig.itemInCart)
            })
            .catch(() => {
                samplesConfig.errorText.classList.add('show-error-text')
                changeButtonText(samplesConfig.standardButtonText)
            })
    } else if (currentCartQuantity > 2) {
        changeButtonText(samplesConfig.fullCart)
    }
}


/**
 * This binds the event listeners to the window and add samples button.
 * @param {Object} customEvents This is an object of events using the Event interface.
 */
function bindEventListeners(customEvents) {
    // Order Samples Button Action
    window.addEventListener('order-samples', actionOrderSamples)

    // Order Samples Button Listener
    if (samplesConfig.orderSamplesButton && customEvents.orderSamplesEvent) {
        samplesConfig.orderSamplesButton.addEventListener('click', function (e) {
            e.preventDefault()
            window.dispatchEvent(customEvents.orderSamplesEvent)
        })
    }
}

/**
 * Update the window's knowledge of line items in the cart.
 * @param {Array} lineItems Array of line items from the cart response.
 */
function updateWindowLineItems(lineItems) {
    const newLineItemIds = []
    for (const key in lineItems) {
        newLineItemIds.push(lineItems[key].purchasableId)
    }
    window.cartItemIds = newLineItemIds
}

/**
 * This is the main controller for adding samples.
 */
export default function initSamples() {
    const colorOptionsElements = document.querySelectorAll('[data-purchasable]')
    if (!colorOptionsElements.length) return

    // Create purchasables from the color options
    let colorOptions = []
    colorOptionsElements.forEach(option => {
        colorOptions.push(new PurchasableOption(option))
    })

    const url = new URL(window.location.href)
    const optionParam = url.searchParams.get('option')
    const hashParam = url.hash

    // Set initial color option. If there is a url param "option" then we look for that option
    // in the list of colors and select it. I used .click() so that it would trigger the product image slider as well.
    if (optionParam || hashParam) {
        colorOptions.forEach(option => {
            const id = option.getPurchasableId()
            const hash = option.getProductHash()
            if (id === optionParam || hash === hashParam) {
                option.getPurchasable().click()
            }
        })
    } else if (!window.currentPurchasable) {
        const initialColorOption = colorOptions[0]
        initialColorOption.addPurchasableToWindow()
    }

    const customEvents = createEvents()
    bindEventListeners(customEvents)
}
