function startVideo(player) {
    if (player) {
        player.play()
            .then(() => {
                // the video was played
            })
            .catch(error => {
                switch (error.name) {
                case 'PasswordError':
                    // the video is password-protected and the viewer needs to enter the
                    // password first
                    break

                case 'PrivacyError':
                    // the video is private
                    break

                default:
                    // some other error occurred
                    break
                }
            })
    }
}

function attachEventListeners(player) {
    if (player) {
        const playerID = player.element.getAttribute('id')
        const playTrigger = document.querySelector(`[data-play="${playerID}"]`)
        if (playTrigger) {
            playTrigger.addEventListener('click', function(e) {
                playTrigger.classList.add('show')
                startVideo(player)
            })
        }
    }
}

async function connectVideoPlayers() {
    const vimeoPlayers = document.querySelectorAll('iframe[data-vimeo]')
    if (vimeoPlayers.length) {
        const {default: Player} = await import(/* webpackChunkName: "vimeo" */ '@vimeo/player')
        vimeoPlayers.forEach(playerElement => {
            const player = new Player(playerElement)
            attachEventListeners(player)
        })
    }
}

export default function init() {
    connectVideoPlayers()
}
