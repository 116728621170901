/**
 * Functionality for the Markets page
 */
import { heightEqualizer } from '../utils'

export default function initMarketPage() {
    const marketsPage = document.querySelector('[data-equalizer]')
    if (!marketsPage) return

    heightEqualizer('.advantages-section', '.column')
}
