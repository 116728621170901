import { ValidationProvider, ValidationObserver, configure } from 'vee-validate';

export async function init() {
    const {default: Vue} = await import(/* webpackChunkName: "vue" */ 'vue')
    const $eventBus = new Vue();
    Vue.prototype.$eventBus = $eventBus;

    configure({
        classes: {
            valid: 'is-valid',
            invalid: 'is-invalid',
            dirty: ['is-dirty']
        }
    })

    Vue.component('ValidationProvider', ValidationProvider);
    Vue.component('ValidationObserver', ValidationObserver);

    if (document.getElementById('sample-request-app-container')) {
        const {default: SampleRequestApp} = await import(/* webpackChunkName: "sample-request" */ '../../vue/SampleRequest/SampleRequestApp');
        new Vue({
            el: '#sample-request-app-container',
            template: '<SampleRequestApp/>',
            components: {SampleRequestApp}
        })
    }

    if (document.getElementById('inspiration-gallery-app')) {
        const {default: ProjectGalleryApp} = await import(/* webpackChunkName: "inspiration-gallery-app" */ '../../vue/ProjectGallery/ProjectGalleryApp');
        const {default: VueMasonry} = await import(/* webpackChunkName: "vue-masonry-css" */ 'vue-masonry-css');
        Vue.use(VueMasonry)
        new Vue({
            el: '#inspiration-gallery-app',
            template: '<ProjectGalleryApp/>',
            components: {ProjectGalleryApp}
        })
    }

    if (document.getElementById('product-filters-app-container')) {
        const {default: ProductFiltersApp} = await import(/* webpackChunkName: "product-filters-app" */ '../../vue/ProductFilters/ProductFiltersApp');
        new Vue({
            el: '#product-filters-app-container',
            template: '<ProductFiltersApp/>',
            components: {ProductFiltersApp}
        })
    }

    if (document.getElementById('featured-projects-module-app-container')) {
        const {default: ProductFiltersApp} = await import(/* webpackChunkName: "product-filters-app" */ '../../vue/FeaturedProjectsModule/FeaturedProjectsModuleApp');
        new Vue({
            el: '#featured-projects-module-app-container',
            template: '<ProductFiltersApp/>',
            components: {ProductFiltersApp}
        })
    }


    if (document.getElementById('gallery-modal-container')) {
        const {default: ProjectModalApp} = await import(/* webpackChunkName: "project-modal-app" */ '../../vue/ProjectModal/ProjectModalApp');
        new Vue({
            el: '#gallery-modal-container',
            template: '<ProjectModalApp/>',
            components: {ProjectModalApp}
        })
    }

    function checkHashForProject() {
        if(location.hash && location.hash.substr(1).startsWith('view-project:')) {
            $eventBus.$emit('view-project', {
                slug: location.hash.substr(14)
            });
        }
    }
    checkHashForProject();
}
