import about from './about';
import contact from './contact';
import gallery from './gallery';
import home from './home';
import product from './product';
import products from './products';
import marketsPage from "./marketsPage";
import blogListingPage from "./blogListingPage";

export default function init() {
    about();
    contact();
    gallery();
    home();
    product();
    products();
    marketsPage();
    blogListingPage();
}
