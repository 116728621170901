/**
 * Sets the current photo's finish name
 */
const updateCurrentFinishName = function () {
    $('[data-product]').each(function () {
        if ($(this).hasClass('active')) {
            var finish_name = $(this).data('title')
            if (finish_name) {
                $('#finish-display').html(finish_name)
            }
        }
    })
}

const updateSlideOnColorChange = function () {
    $('[data-product]').on('click', function () {
        var ele = $(this)
        finishThumbChange(ele)

        var colorOptionSlug = ele.data('colorOption')
        finishHeroGotoSlide(colorOptionSlug)
    })
}

const finishThumbChange = function(ele) {
    var product = ele.data('product')
    $('[data-product]').removeClass('active')
    ele.addClass('active')
    $('.showcase-image').siblings().removeClass('active')
    $('#' + product).addClass('active')
    updateCurrentFinishName()
    return true
}

const initProductHero = function (initialSlide) {
    $('#product-carousel').slick({
        autoplay: false,
        speed: 800,
        arrows: true,
        draggable: true,
        fade: false,
        focusOnSelect: true,
        adaptiveHeight: false,
        infinite: false,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        initialSlide: initialSlide
    })
}

const setupImagePopups = function () {
    if (document.getElementById('product-carousel')) {
        // Product Gallery Popup
        $('.product-zoom-popup').magnificPopup({
            type: 'image',
            preloader: true,
            titleSrc: 'title',
            removalDelay: 300,
            mainClass: 'mfp-fade',
            gallery: {
                enabled: true
            }
        });
    }
}

const setupVideoCarousel = function () {
    if (document.querySelectorAll('.video-carousel').length) {
        $('.video-carousel').slick({
            dots: false,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false
        })
    }
}

const setupWidthsCarousel = function () {
    if (!document.getElementById('available-widths')) {
        return;
    }
    $('#available-widths').slick({
        autoplay: false,
        arrows: true,
        draggable: true,
        focusOnSelect: true,
        adaptiveHeight: true,
        pauseOnHover: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    })
}

const setupAccessoriesCarousel = function () {
    if (document.getElementById('accessories-carousel')) {
        $('#accessories-carousel').slick({
            autoplay: false,
            autoplaySpeed: 5000,
            speed: 100,
            arrows: true,
            draggable: true,
            fade: false,
            focusOnSelect: true,
            adaptiveHeight: false,
            infinite: false,
            pauseOnHover: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: true,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 568,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        })
    }
}

const finishHeroGotoSlide = function(colorOptionSlug) {
    var $slide = $('.slick-slider [data-vignette="' + colorOptionSlug + '"]').parent().parent('.slick-slide')
    var slideIndex = $slide.data('slickIndex')
    $('#product-carousel').slick('slickGoTo', slideIndex)
}

const finishHeroInitSlide = function(hash) {
    var ids = 0
    var done = false
    $('#product-carousel [data-product-hash]').each(function () {
        if ($(this).data('product-hash') === hash && !done) {
            initProductHero(ids)
            done = true
        }
        ids++
    })
}

const checkFinishHash = function() {
    var hash = window.location.hash
    if (hash && hash.length > 1) {
        if (setFinish(hash)) {
            finishHeroInitSlide(hash)
        } else {
            initProductHero()
        }
    } else {
        // If no hash in URL, set the finish to the first item and update the product hero appropriately
        setFinish(null)
        initProductHero()
    }
}


const setFinish = function(hash) {
    let foundSlide = false
    $('[data-product]').each(function () {
        const productHash = $(this).data('product-hash');
        var ele = $(this)
        if (productHash && hash) {
            if (productHash === hash) {
                foundSlide = true
                $(this).addClass('active')
                finishThumbChange(ele)
            }
        }
    })
    if (!hash || !foundSlide) {
        $('.finishes-thumbs').find('ul li:first-child a').addClass('active')
        $('#showcase').find('.showcase-image:first-child').addClass('active')
    }
    return foundSlide;
}


function init() {
    jQuery(document).ready(function ($) {
        updateCurrentFinishName()
        updateSlideOnColorChange()

        checkFinishHash()

        // More decorative sliders and stuff
        setupAccessoriesCarousel()
        setupWidthsCarousel()
        setupVideoCarousel();
        setupImagePopups();
    })
}

export default init;
