/**
 * Cart Button Events
 * These are the events that you can trigger other places to update the cart icon.
 * Specifically, to update the number of items in the cart.
 */

const config = {
    cartButton: document.querySelector('div.cart-button')
}

/**
 * Update Cart Quantity
 * @param {Number} quantity Number of items in the cart. Should be the total number of items.
 */
export function updateCartQuantity(quantity) {
    // Get the cart button
    const cartQuantityElement = config.cartButton.querySelector('.cart-item-count')
    // Update the cart's quantity
    if (cartQuantityElement && quantity) {
        cartQuantityElement.textContent = quantity
        cartQuantityElement.setAttribute('data-cart-quantity', quantity)
        return true
    } else {
        return new Error('The cart icon does not exist or you did not give a quantity.')
    }
}

/**
 * Get Cart Quantity
 * Use this to get the current cart quantity.
 * @returns {Number} Returns a whole number integer of items in the cart.
 */
export function getCartQuantity() {
    const cartQuantityElement = config.cartButton.querySelector('.cart-item-count')
    if (cartQuantityElement) {
        return parseInt(cartQuantityElement.getAttribute('data-cart-quantity'))
    } else {
        return false
    }
}
