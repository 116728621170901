/**
 * Initializes the slider on the product listing page with quotes
 */
const initQuoteSlider = function () {
    $('#quote-image').slick({
        slidesToShow: 1,
        arrows: false,
        fade: true,
        asNavFor: '#quotes'
    })

    $('#quotes').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true,
        draggable: true,
        fade: true,
        focusOnSelect: true,
        adaptiveHeight: false,
        infinite: true,
        pauseOnHover: true,
        slidesToShow: 1,
        asNavFor: '#quote-image',
        responsive: [
            {
                breakpoint: 567,
                settings: {
                    arrows: false
                }
            }
        ]
    })
}

/**
 * Initializes the slider on the product listing page that shows "more products"
 */
const initMoreProductsSlider = function () {
    $('#more-products-carousel').slick({
        autoplay: true,
        arrows: false,
        draggable: true,
        infinite: true,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    arrows: true
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    arrows: true
                }
            }
        ]
    })
}

const initTooltip = function () {
    $(document).on('mouseenter', '[data-prodtooltip]', function() {
        $(this).next().delay(250).fadeIn(100)
    });
    $(document).on('mouseleave', '[data-prodtooltip]', function() {
        $(this).next().fadeOut(100)
    });
}

function init() {
    jQuery(document).ready(function ($) {
        initQuoteSlider()
        initMoreProductsSlider()
        initTooltip()
    })
}

export default init
