// height equalizer because foundation's fails sometimes
export function heightEqualizer(container, element) {
    var maxHeight = 0;
    jQuery(container).find(element).each(function () {
        jQuery(this).height('auto');
        var tempHeight = jQuery(this).height();
        if (tempHeight > maxHeight) {
            maxHeight = tempHeight;
        }
    });
    jQuery(container).find(element).each(function () {
        jQuery(this).height(maxHeight);
    });
}

/**
 *
 */
export function smoothScroll() {
    $('a.scroll').click(function(e) {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 500);
                e.preventDefault();
            }
        }
    });
}