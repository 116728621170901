/**
 * Entry point for all the site components
 */
import vimeoPlayer from './vimeoPlayer'
import productPage from './globalProductConfig'

export default function init() {
    vimeoPlayer()
    productPage()
}
