import axios from 'axios'

const apiUrl = '/api'
const endpoints = {
    products: `${window.location.origin}${apiUrl}/color-options.json`,
    addToCart: `${window.location.origin}`, // Should just be the siteURL for the commerce API
}

const API = axios.create({
    timeout: 20000,
    headers: {
        'Content-Type': 'application/json'
    }
})

function requestCreator() {
    let call
    return (url, options) => {
        if (call) {
            call.cancel('too many requests')
        }
        call = axios.CancelToken.source()
        return API.request(url, {
            ...options,
            cancelToken: call.token
        })
    }
}

export const cancellableRequest = requestCreator()

function postSamples(data) {
    return cancellableRequest(endpoints.addToCart, {
        method: 'POST',
        data,
        responseType: 'json',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
        }
    })
}


function getFilteredProducts(data) {
    return cancellableRequest(endpoints.products, {
        method: 'POST',
        data,
        responseType: 'json',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
}

function getNextProducts(endpoint, data) {
    return cancellableRequest(endpoint, {
        method: 'POST',
        data,
        responseType: 'json',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
}

export default {
    getFilteredProducts,
    getNextProducts,
    postSamples
}
