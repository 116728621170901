// Icons
import '../images/svgs/add-to-cart.svg'
import '../images/svgs/triangle-icon.svg';

// Logos
import '../images/nydree-logo-white.png'
import '../images/nydree-logo-white@2x.png'
import '../images/nydree-logo.png'
import '../images/nydree-logo@2x.png'
import '../images/Nydree-Logo-Website.png'

// About page
import '../images/p686-mission.jpg'
import '../images/p686-logo.png'

// Some fonts
import '../fonts/32B097_0_0.eot';
import '../fonts/32B097_0_0.ttf';
import '../fonts/32B097_0_0.woff';
import '../fonts/32B097_0_0.woff2';
import '../fonts/32B097_1_0.eot';
import '../fonts/32B097_1_0.ttf';
import '../fonts/32B097_1_0.woff';
import '../fonts/32B097_1_0.woff2';
import '../fonts/32B097_2_0.eot';
import '../fonts/32B097_2_0.ttf';
import '../fonts/32B097_2_0.woff';
import '../fonts/32B097_2_0.woff2';
import '../fonts/32B097_3_0.eot';
import '../fonts/32B097_3_0.ttf';
import '../fonts/32B097_3_0.woff';
import '../fonts/32B097_3_0.woff2';
import '../fonts/32B097_4_0.eot';
import '../fonts/32B097_4_0.ttf';
import '../fonts/32B097_4_0.woff';
import '../fonts/32B097_4_0.woff2';
import '../fonts/32B097_5_0.eot';
import '../fonts/32B097_5_0.ttf';
import '../fonts/32B097_5_0.woff';
import '../fonts/32B097_5_0.woff2';
import '../fonts/filtering-icons.eot';
import '../fonts/filtering-icons.svg';
import '../fonts/filtering-icons.ttf';
import '../fonts/filtering-icons.woff';
import '../fonts/nydree-icons.eot';
import '../fonts/nydree-icons.svg';
import '../fonts/nydree-icons.ttf';
import '../fonts/nydree-icons.woff';
