function init() {
    jQuery(document).ready(function ($) {

        // adjust height of home-gallery
        function home_height() {
            let header_height = $('#header').height();
            let window_height = $(window).height();
            let adjusted_height = window_height - header_height;
            // console.log(header_height);
            // console.log(window_height);
            // console.log(adjusted_height);
            $('#home-block').height(adjusted_height);
        }

        home_height();
        $(window).resize(function () {
            home_height();
        });
        if (document.getElementById('#industries') && document.getElementById('#home-images')) {
            $('#industries').slick({
                autoplay: true,
                autoplaySpeed: 2000,
                arrows: false,
                asNavFor: '#home-images',
                centerMode: true,
                centerPadding: '30px',
                draggable: true,
                fade: false,
                focusOnSelect: true,
                infinite: true,
                mobileFirst: true,
                pauseOnHover: false,
                slidesPerRow: 8,
                slidesToShow: 1,
                variableWidth: true
            });

            $('#home-images').on('init', function () {
                $('#home-images').fadeIn(1000);
            }).slick({
                autoplay: true,
                autoplaySpeed: 2000,
                arrows: false,
                asNavFor: '#industries',
//        centerMode: true,
//        centerPadding: '30px',
                draggable: true,
                fade: true,
                focusOnSelect: true,
//        mobileFirst: true,
                pauseOnHover: false,
                slidesPerRow: 1,
                slidesToShow: 1,
//        variableWidth: true
            });
        }

        if (document.getElementById('#clients-scroller')) {
            $('#clients-scroller').on('init', function () {
                $('#clients-wrapper').fadeIn(1000);
            }).slick({
                autoplay: true,
                autoplaySpeed: 3500,
                arrows: true,
                draggable: true,
                focusOnSelect: true,
                pauseOnHover: false,
                slidesToShow: 3,
            });
        }

        if (document.querySelector('.clients')) {
            $('.clients').slick({
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 4
                        }
                    },
                    {
                        breakpoint: 640,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 2
                        }
                    }
                ]
            });
        }

        if (document.querySelector('.comparisonSlide')) {
            $('.comparisonSlide').slick();
        }

        $(document).foundation('equalizer', 'reflow');
    });
}

export default init;
