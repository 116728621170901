function init() {
    jQuery(document).ready(function ($) {
        if (document.getElementById('inspiration-page')) {
            // Slider Book init
            $('.slider-book-preview').slick();
            $('.slider-book-thumb').slick({
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 2
                        }
                    }
                ]
            });
            //portfolio interactions
            $('[data-cat]').on('click', function () {
                $('[data-cat],[data-all]').removeClass('active');
                $(this).addClass('active');
                var portfolio_height = $('#portfolio-thumbs').height();
                $('#portfolio-thumbs').height(portfolio_height);
                var id = $(this).data('cat');
                $('[data-cats]').fadeOut(100);
                $('[data-cats]').each(function (index) {
                    var categories = $(this).data('cats');
                    if ($.inArray(id, categories) !== -1) {
                        $(this).delay(100).fadeIn(100);
                    }
                });
                return false;
            });
            $('[data-all]').on('click', function () {
                $('[data-cat]').removeClass('active');
                $(this).addClass('active');
                $('[data-cats]').fadeOut(100).delay(100).fadeIn(100);
                return false;
            });

            // gallery stuff
            $('[data-gallery]').on('click', function () {
                var gallery = $(this).data('gallery');
                for (i = 0; i < gallery.length; i++) {
                    $('#gallery-slider').append('<div class="gallery-image" style="background-image:url(' + gallery[i].image.image_src.large[0] + ');"><div class="gallery-caption">' + gallery[i].caption + '</div></div>');
                }
                $('#gallery').fadeIn(100);
                $('#gallery-slider').slick();
                $('body').addClass('noscroll');
                $('.slick-active').focus();
                return false;
            });

            function closeGallery() {
                $('#gallery').fadeOut(100);
                $('#gallery-slider').slick('unslick').html('');
                $('body').removeClass('noscroll');
            }

            $('[data-close-gallery]').on('click', function () {
                closeGallery();
                return false;
            });
            $(document).keyup(function (e) {
                if (e.keyCode == 27) {
                    closeGallery();
                    return false;
                }
            });
            // Init image gallery
            $('.project-gallery').each(function () { // the containers for all your galleries
                $(this).find('a').magnificPopup({
                    type: 'image',
                    preloader: true,
                    removalDelay: 300,
                    mainClass: 'mfp-fade',
                    markup: '<div class="mfp-figure"><div class="mfp-close"><span class="icon-close"></span></div>' +
                        '<figure>' +
                        '<figcaption>' +
                        '</figcaption>' +
                        '</figure>',
                    titleSrc: 'title',
                    gallery: {
                        enabled: true
                    },

                });
            });
        }
    });
}

export default init
