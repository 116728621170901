/**
 * Add Samples Button Events
 * These are the events that you can trigger on the order samples button.
 */
const config = {
    addSampleButton: document.querySelector('#order-samples-button')
}

/**
 * Update the text of the add samples button. Used for loading.
 * @param {String} text Text to show in the button.
 */
export function changeButtonText(text) {
    if (config.addSampleButton && text) {
        config.addSampleButton.textContent = text
        return true
    } else {
        return new Error('Either the button doesn\'t exist or you didn\'t provide text.')
    }
}
export function setButtonEnabled(enabled) {
    if (config.addSampleButton) {
        config.addSampleButton.disabled = !enabled
    }
}