/**
 * Change Tabs
 * @param {Event} e Click event of the tab change.
 */
function changeTab(e) {
    e.preventDefault()
    const tab = $(this)
    const tabTarget = e.target.getAttribute('href')
    const panel = $(tabTarget)
    panel.siblings().removeClass('active')
    panel.addClass('active')
    tab.parent().siblings().removeClass('active')
    tab.parent().addClass('active')
}

export default function initTabs() {
    const tabs = $('#product-specs-nav li a')
    tabs.on('click', changeTab)
}
