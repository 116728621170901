function init() {
    jQuery(document).ready(function ($) {
        if (document.getElementById('faqs')) {
            //portfolio interactions
            $('[data-question]').on('click', function () {
                var question = $(this).data('question');
                var answer = $(this).data('answer');
                $('#faq-question').html(question);
                $('#faq-answer').html(answer);
                $('#faq-modal').foundation('reveal', 'open');
                return false;
            });
        }
    });
}

export default init;
