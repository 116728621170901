// Tabs for Product Specs
import initTabs from './changeTabsModule'
import initSamples from './addSamplesModule'


/**
 * Default Export
 */
export default function initProductPage() {
    initTabs()
    initSamples()
}
