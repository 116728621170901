import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import lazySizes from 'lazysizes';

// polyfills
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'picturefill';

import $ from 'jquery'

import 'foundation-sites/js/foundation/foundation'
import 'foundation-sites/js/foundation/foundation.magellan'
import 'foundation-sites/js/foundation/foundation.topbar'
import 'foundation-sites/js/foundation/foundation.reveal'
import 'foundation-sites/js/foundation/foundation.tooltip'
import 'foundation-sites/js/foundation/foundation.tab'

import {smoothScroll} from './utils'

import './static-assets';

import '../sass/app.scss';

import components from './components/index';
import './components/vueApps';

import pages from './pages';
import {init as InitVue} from "./components/vueApps";

$(document).ready(function ($) {
    // Start foundation
    $(document).foundation();

    // Setup smooth scrolling
    smoothScroll()
});

// Set background images lazily
document.addEventListener('lazybeforeunveil', function (e) {
    var bg = e.target.getAttribute('data-bg');
    if (bg) {
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});

Promise.all([
    import(/* webpackChunkName: "slick" */ 'slick-carousel'),
    import(/* webpackChunkName: "magnific-popup" */ 'magnific-popup')
]).then(() => {
    pages();
    InitVue();
    components();
});


// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
